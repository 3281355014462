import{FooterContainer, FooterInner, FooterText, FooterIcons, FooterIcon} from './footer.styles';

const Footer = () =>{
	return (
		<FooterContainer>
				<FooterInner className='content_inner'>
					<FooterText>
					Copyright © 2024 Colegiul Medicilor din România
					</FooterText>
							
				</FooterInner>
		</FooterContainer>
	
	)
}

export default Footer;