import { Link } from 'react-router-dom';

import { HomeDiv} from "./home.styles";
import Alegeri2024 from '../alegeri2024/alegeri2024.component';

const Home = () =>{
	return (
		<div>
			<HomeDiv className="content_inner">
			<Alegeri2024/>
				
			</HomeDiv>
			
		</div>
	
	);
}

export default Home;