import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';

import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
import Alegeri2024 from './routes/alegeri2024/alegeri2024.component';
import Conducere from './routes/conducere/conducere.component';
import Contact from './routes/contact/contact.component';
import ComisiiDeSpecialitate from './routes/comisii_de_specialitate/comisii_de_specialitate.component';
import TagManager from 'react-gtm-module'


import './App.css';





function App() {
	useEffect(() => {
		TagManager.initialize({ gtmId: 'G-NRS1V5KBD5' });
	}, []);

  return (
		<Routes>
			<Route path="/" element={<Navigation />} >
				<Route index element={ <Home />} />
				<Route path='alegeri2024' element={<Alegeri2024 />} />
				<Route path='conducere' element={<Conducere />} />
				<Route path='comisii_de_specialitate' element={<ComisiiDeSpecialitate />} />
				<Route path='contact' element={<Contact />} />
			</Route>
				
		</Routes>

  );
}

export default App;
