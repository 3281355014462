import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { BooksProvider } from './contexts/books.context';
import { CartProvider } from './contexts/cart.context';


import Footer from './components/footer/footer.component';


import App from './App';

import './index.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
		
			<BrowserRouter>
				<BooksProvider>
					<CartProvider>
						
						<div id="content">
							<App />
						</div>
						<Footer></Footer>
				
					</CartProvider>
				</BooksProvider>
			
			</BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
