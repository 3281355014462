
import { Fragment } from 'react';
import { Outlet} from 'react-router-dom';




import {NavigationContainer, NavLinks, NavLink, LogoContainer,LogoTitle } from './navigation.styles';


const Navigation = () => {
	return (
		<Fragment>
		<NavigationContainer className='content_inner'>
		
			<LogoContainer to='/'>
					<img src="/img/logo.gif" alt="signature" />
					<LogoTitle>CMHR</LogoTitle>
			</LogoContainer>
				
			<NavLinks>
				<NavLink to='/conducere'>Conducere</NavLink>
				<NavLink to='/alegeri2024'>Alegeri 2024</NavLink>
				<NavLink to='/comisii_de_specialitate'>Comisii De Specialitate</NavLink>
				<NavLink to='/contact'>Contact</NavLink>
			</NavLinks>
			</NavigationContainer>
			<Outlet />
		
		</Fragment>
	)
}

export default Navigation;