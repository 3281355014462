const Conducere = () => {

	return (
		<div className="content_inner">
			<h1>Conducere</h1>
			<p>
				<b>Biroul Executiv:</b> Dr. Biró László Președintele Colegiului Teritorial Harghita
			</p>
			<p>
				<b>Vicepreședinți:</b> Dr.Szöllösi Attila, Dr.Könczei Csaba, Dr.Szakács Oszkár Balázs
			</p>
			<p>
				<b>Secretar:</b> Dr.Solti Norbert
			</p>
			<p>
				<b>Consiliul Colegiului este format din 13 persoane:</b> Dr. Biró László, Dr. Solti Norbert, Dr. Bartalis Zsolt, Dr. Szöllösi Attila, Dr. Györfi Sándor, Dr. Lakatos Miklós Zsolt, Dr. Tubák Nimród, Dr. Szakács Oszkár Balázs, Dr. Lőkös Barna Csaba, Dr. Sinkó János, Dr. Kacsó István Lajos, Dr. László Zsuzsanna, Dr. Könczei Csaba
			</p>
			<p>
				<b>Reprezentant în Consiliul Național al CMR:</b> Dr. Sinkó János
			</p>
			<p>
				<b>Comisia de cenzori:</b> Dr. Kovács Emőke, Dr. Domokos Melinda, Dr. Székely Judit
			</p>
			<p>
				<b>Reprezentanți în Adunarea Generală a CMR:</b> Dr. Biró László, Dr. Györfi Sándor, Dr. Szöllösi Attila
			</p>
		</div>
	)
}

export default Conducere;